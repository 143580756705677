<template>
  <transition
    leave-to-class="animate-fade-out"
    enter-active-class="animate-fade-in"
  >
    <Card v-if="show" class="w-80">
      <div class="flex items-start justify-between border-b-2">
        <div>
          <p class="text-xs text-gray-400">New Poll</p>
          <h1 class="text-lg">{{ question.title }}</h1>
        </div>
        <button @click="closePoll" type="button" class="p-1 group">
          <XIcon class="icon-sm icon-action" />
        </button>
      </div>
      <ul class="mt-2 space-y-2">
        <li
          class="list-item"
          v-for="answer in question.answers"
          :key="answer.id"
          @click="selectAnswer(answer.id)"
        >
          <div>
            <Checkbox :selected="selectedAnswer === answer.id" />
          </div>
          <div>
            {{ answer.text }}
          </div>
        </li>
      </ul>
      <div class="flex items-center mt-2 space-x-2">
        <Button primary @click="handleSubmit">Vote</Button>
        <Spinner v-if="loading" class="w-6 text-red-500" />
      </div>
    </Card>
  </transition>
</template>

<script>
import { reactive, ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { db } from "@/firebase";
import axios from "axios";

import Button from "./shared/Button";
import Card from "./shared/Card";
import Checkbox from "./shared/Checkbox";
import Spinner from "./shared/Spinner";
import { XIcon } from "./icons";

import { useSessionStorageArray } from "../composables";

export default {
  components: { Button, Card, Checkbox, Spinner, XIcon },
  setup() {
    const selectedAnswer = ref("");
    const completed = ref(false);
    const show = ref(false);
    const question = reactive({
      id: "",
      title: "",
      answers: [],
    });
    const store = useStore();
    const loading = ref(false);
    const answeredQuestions = useSessionStorageArray("answeredQuestions");
    const currQuestion = computed(() => store.state.settings.question);

    const fetchQuestion = async () => {
      const id = currQuestion.value;
      if (id !== question.id) {
        if (!id) {
          show.value = false;
        } else if (!answeredQuestions.includes(id)) {
          const questionRef = db.collection("questions").doc(id);
          const fetchedQuestion = await questionRef.get();
          const fetchedAnswers = (
            await questionRef.collection("answers").get()
          ).docs.map(d => ({ id: d.id, ...d.data() }));

          question.id = fetchedQuestion.id;
          question.title = fetchedQuestion.data().title;
          question.answers = fetchedAnswers.sort((a, b) => a.order - b.order);

          completed.value = false;
          show.value = true;
        }
      } else if (id && !answeredQuestions.includes(id)) {
        show.value = true;
      }
    };
    fetchQuestion();

    watch(currQuestion, () => {
      fetchQuestion();
    });

    const selectAnswer = answerId => {
      if (!completed.value) {
        selectedAnswer.value = answerId;
      }
    };

    const handleSubmit = async () => {
      if (selectedAnswer.value && !completed.value) {
        completed.value = true;
        loading.value = true;

        try {
          await axios.get(process.env.VUE_APP_ADD_ANSWER_URL, {
            params: {
              q: question.id,
              a: selectedAnswer.value,
            },
          });
          answeredQuestions.add(question.id);
        } catch (e) {
          console.error(e);
        } finally {
          show.value = false;
          loading.value = false;
        }

        answeredQuestions.add(question.id);
        show.value = false;
        loading.value = false;
      }
    };

    const closePoll = () => {
      answeredQuestions.add(question.id);
      completed.value = true;
      show.value = false;
    };

    return {
      show,
      question,
      selectedAnswer,
      selectAnswer,
      handleSubmit,
      completed,
      closePoll,
      loading,
    };
  },
};
</script>
