export const useSessionStorageArray = key => {
  const get = () => {
    return JSON.parse(window.sessionStorage.getItem(key)) || [];
  };

  const add = value => {
    window.sessionStorage.setItem(key, JSON.stringify([...get(), value]));
  };

  const includes = value => {
    return get().includes(value);
  };

  return { get, add, includes };
};
